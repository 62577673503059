import { graphql, Script } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import Courses from "../components/campword/Courses";
import Framework from "../components/campword/Framework";
import Hero from "../components/campword/Hero";

const CampWordsmith = ({ data: { sanityBookPage, sanitySite } }) => {
  return (
    <Fragment>
      <Layout headerData={sanitySite?.header}>
        <Script
          async
          strategy="idle"
          src="https://tinder.thrivecart.com/embed/v1/thrivecart.js"
        ></Script>
        <Hero heroSection={sanityBookPage.heroSection} />
        <div className={""}>
          <section
            className={
              "sm:p-20 p-10 sm:my-10 my-5 bg-[url(../images/campwordsmith-r-img-bg.png)] bg-no-repeat sm:bg-[length:100%_100%]"
            }
          >
            <div className={"grid grid-cols-5 gap-4 items-center"}>
              <div
                className={"col-span-5 md:col-span-3 py-16 md:order-1 order-2"}
              >
                <div className={"max-w-xl mx-auto"}>
                  <h1 className={"sm:text-[44px] text-3xl mb-5"}>
                    {sanityBookPage.provenFrameworkSection?.title}
                  </h1>
                  <p className={"font-lora sm:text-sm"}>
                    {sanityBookPage.provenFrameworkSection?.description}
                  </p>
                </div>
              </div>
              <div
                className={"col-span-5 md:col-span-2 -my-8 md:order-2 order-1"}
              >
                <GatsbyImage
                  image={
                    sanityBookPage.provenFrameworkSection?.image?.asset
                      ?.gatsbyImageData
                  }
                  alt={"Nick"}
                />
              </div>
            </div>
          </section>
          <section
            className={
              "sm:p-20 p-10 sm:my-10 my-5 bg-[url(../images/campwordsmith-l-img-bg.png)] bg-no-repeat sm:bg-[length:100%_100%]"
            }
          >
            <div className={"grid grid-cols-5 gap-4 items-center"}>
              <div className={"col-span-5 md:col-span-2 -my-8"}>
                <GatsbyImage
                  image={
                    sanityBookPage.customBuiltPlatformSection?.image?.asset
                      ?.gatsbyImageData
                  }
                  alt={"Nick"}
                  height={450}
                />
              </div>
              <div className={"col-span-5 md:col-span-3 py-10"}>
                <div className={"max-w-xl mx-auto"}>
                  <h1 className={"sm:text-[44px] text-3xl mb-5"}>
                    {sanityBookPage.customBuiltPlatformSection?.title}
                  </h1>
                  <p className={"font-lora sm:text-sm"}>
                    {sanityBookPage.customBuiltPlatformSection?.description}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
            className={
              "sm:p-20 p-10 sm:my-10 my-5 bg-[url(../images/campwordsmith-r-img-bg.png)] bg-no-repeat sm:bg-[length:100%_100%]"
            }
          >
            <div className={"grid grid-cols-5 gap-4 items-center"}>
              <div
                className={"col-span-5 md:col-span-3 py-16 md:order-1 order-2"}
              >
                <div className={"max-w-xl mx-auto"}>
                  <h1 className={"sm:text-[44px] text-3xl mb-5"}>
                    {sanityBookPage.monthlyUpdatesNewsletterSection?.title}
                  </h1>
                  <p className={"font-lora sm:text-sm"}>
                    {
                      sanityBookPage.monthlyUpdatesNewsletterSection
                        ?.description
                    }
                  </p>
                </div>
              </div>
              <div
                className={"col-span-5 md:col-span-2 -my-8 md:order-2 order-1"}
              >
                <GatsbyImage
                  image={
                    sanityBookPage.monthlyUpdatesNewsletterSection?.image?.asset
                      ?.gatsbyImageData
                  }
                  alt={"Nick"}
                />
              </div>
            </div>
          </section>
          <section
            className={
              "sm:p-20 p-10 sm:my-10 my-5 bg-[url(../images/campwordsmith-l-img-bg.png)] bg-no-repeat sm:bg-[length:100%_100%]"
            }
          >
            <div className={"grid grid-cols-5 gap-4 items-center"}>
              <div className={"col-span-5 md:col-span-2 -my-8"}>
                <GatsbyImage
                  image={
                    sanityBookPage.operatedByAnLGBTQSection?.image?.asset
                      ?.gatsbyImageData
                  }
                  alt={"Nick"}
                  height={450}
                />
              </div>
              <div className={"col-span-5 md:col-span-3 py-10"}>
                <div className={"max-w-xl mx-auto"}>
                  <h1 className={"sm:text-[44px] text-3xl mb-5"}>
                    {sanityBookPage.operatedByAnLGBTQSection?.title}
                  </h1>
                  <p className={"font-lora sm:text-sm"}>
                    {sanityBookPage.operatedByAnLGBTQSection?.description}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        {sanityBookPage?.coursesSection?.courses?.filter(
          (item) => item.featured
        )?.length === 0 && <Framework topSection={sanityBookPage.topSection} />}
        <Courses coursesSection={sanityBookPage.coursesSection} />
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default CampWordsmith;
export const Head = ({ data: { sanityBookPage } }) => (
  <SEO metadata={sanityBookPage?.pageMetadata || {}} />
);
export const query = graphql`
  query {
    sanityBookPage {
      coursesSection {
        courses {
          detail
          id
          thriveCartProductID
          price
          learnMore
          featured
          thumbnail {
            asset {
              gatsbyImageData
            }
          }
          title
        }
        title
      }
      title
      id
      heroSection {
        bgImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        title
        subTitle
        caption
      }
      topSection {
        title
        description
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
      provenFrameworkSection {
        image {
          asset {
            gatsbyImageData(height: 320, placeholder: BLURRED, formats: WEBP)
          }
          caption
          alt
        }
        description
        title
      }
      customBuiltPlatformSection {
        image {
          asset {
            gatsbyImageData
          }
          caption
          alt
        }
        description
        title
      }
      monthlyUpdatesNewsletterSection {
        image {
          asset {
            gatsbyImageData(height: 400, placeholder: BLURRED, formats: WEBP)
          }
          caption
          alt
        }
        description
        title
      }
      operatedByAnLGBTQSection {
        image {
          asset {
            gatsbyImageData
          }
          caption
          alt
        }
        description
        title
      }
      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
    sanitySite {
      header {
        links {
          type
          label
          path
          url
          childMenu {
            type
            label
            path
            url
          }
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
        medium
        substack
        youtube
        tiktok
      }
    }
  }
`;
